/* Scope the styles to .site class */
.site {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
}

.site h1, 
.site h2 {
  color: #333;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 37px;
  font-weight: 400;
}

.site p {
  color: #333;
}

.site ul {
  list-style-type: disc;
}

.site a {
  /* color: #E5923B; */
  color: #fff;
  text-decoration: none;
}

.site a:hover {
  text-decoration: underline;
}

.site #cta-one,
.site #about,
.site #values,
.site #team,
.site #services {
  h2 {
    /* color: #333; */
    font-size: 37px;
    font-weight: 400;
  }
}

.site .statement {
  font-family: 'Source Sans Pro', sans-serif;
}